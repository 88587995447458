@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  /* user-select: none; */
  /* font-family: 'Abril Fatface', cursive; */
}

html {
  font-size: 1vw;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#loder{
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 50;
    background-size: 30%;
}
@media only screen and (max-width: 450px) {
  html {
    font-size: 2vw;
  }
}
